<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CAlert color="danger" closeButton v-if="error !== null">
                    {{ error }}
                  </CAlert>
                  <CInput
                    placeholder="Username"
                    autocomplete="username"
                    v-model="username"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6"></CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="primary" type="submit" class="px-4" v-on:click="handleSubmit">Login</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Login',
  data() {
    return {
      username : '',
      password : '',
      error: null
    }
  },
  methods : {
    handleSubmit(e){
      e.preventDefault()
      this.error = null
      if (this.password.length > 0) {
        axios.post(process.env.VUE_APP_API_ENDPOINT + '/login', {
          username: this.username,
          password: this.password
        })
        .then(response => {
          const is_admin = response.data.user.is_admin
          localStorage.setItem('jwt',response.data.token)
          localStorage.setItem('user',JSON.stringify(response.data.user))

          if (localStorage.getItem('jwt') != null){
            this.$emit('loggedIn')
            if(this.$route.params.nextUrl != null){
              this.$router.push(this.$route.params.nextUrl)
            } else {
              if (is_admin) {
                this.$router.push({ name: 'TYPO3 Project List' })
              } else {
                this.$router.push({ name: 'Partner' })
              }
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            this.error = error.response.data.message
          } else {
            this.error = error
          }
        })
      }
    }
  }
}
</script>
